import sanitizeHtml from 'sanitize-html';

const sanitize = {
  strict(text) {
    return sanitizeHtml(text, {
      allowedTags: ['b', 'i', 'em', 'strong', 'a', 'u', 'br', 'p'],
      allowedAttributes: {
        a: ['href', 'target', 'rel'],
      },
      transformTags: {
        a: sanitizeHtml.simpleTransform('a', { rel: 'noreferrer noopener' }),
      },
    });
  },
  safe(text) {
    return sanitizeHtml(text, {
      allowedTags: ['br'],
      allowedAttributes: [],
    });
  },
  strip(text) {
    return sanitizeHtml(text, {
      allowedTags: [],
      allowedAttributes: {},
    });
  },
};

export default sanitize;
